import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import amplifyConfigure from './common_features/config/amplifyConfigure';
import MainRoutes from './MainRoutes'
import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'antd/dist/antd.css';
import { store, persistor } from './common_features/redux/store';
import { PersistGate } from 'redux-persist/integration/react'

amplifyConfigure()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MainRoutes />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
