import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { getReactEnv } from '../utility/helper';
// import rootReducer from './reducers';
import {storeStack} from 'ccw-frontend-common'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['login', "userDetails"],
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
      onError: function (error) {
        // Handle the error.
        console.log(error, "error")
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, storeStack.reducerStack)

let store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    const env = getReactEnv();
    if (['dev'].includes(env)) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

let persistor = persistStore(store)

export { store, persistor }

// const store = configureStore({
//   reducer: storeStack.reducerStack,
//   middleware: (getDefaultMiddleware) => {
//     const env = getReactEnv();
//     if (['dev'].includes(env)) {
//       return getDefaultMiddleware().concat(logger);
//     }
//     return getDefaultMiddleware();
//   },
// });

// export default store;
